/* General Reset */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navigation Bar */
nav {
  background-color: #282c34;
  padding: 10px;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

nav a {
  color: white;
  margin: 0 15px;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

nav a:hover {
  color: #61dafb;
}

nav a.active {
  font-weight: bold;
  text-decoration: underline;
}

/* Projects Section */
.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  width: 100%;
  max-width: 1200px;
}

.project-card {
  position: relative;
  aspect-ratio: 1/1; /* Ensures a square layout */
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  background: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.project-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px;
  text-align: center;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%; /* Adjust width for smaller screens */
  max-width: 600px;
  max-height: 80vh; /* Limit height to viewport height */
  overflow-y: auto; /* Add scrolling if content overflows */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 8px 16px;
  background-color: #282c34;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.close-button:hover {
  background-color: #50575d;
}

.modal img {
  width: 100%; /* Use full width of the modal */
  height: auto;
  display: block;
  margin: 0 auto 20px; /* Center the image and add spacing */
}

.modal-content {
  text-align: center;
}

.modal-content h1 {
  margin-bottom: 20px;
  font-size: 24px;
}

.modal-content p {
  margin-bottom: 10px;
  font-size: 16px;
}

/* About Section */
.about {
  padding: 16px;
  text-align: center;
}

.about p {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
}

.about-link {
  display: inline-block;
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #0073b1;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.about-link:hover {
  background-color: #005a8c;
}

/* Button Styling */
.button {
  padding: 8px 16px;
  background-color: #282c34;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #50575d;
}

/* Smooth Scrolling */
html {
  scroll-behavior: smooth;
}

/* Mobile-Specific Styles */
@media (max-width: 600px) {
  .modal {
    padding: 15px; /* Reduce padding for smaller screens */
    max-height: 90vh; /* Allow more vertical space */
  }

  .project-info {
    font-size: 12px;
  }

  nav a {
    font-size: 16px;
  }
}
